.sf-login-button {
    width: 12rem;
    height: 4rem;
}

.sf-login-box {
    width: 100%;
    height: 4rem;
}

.sf-login-error {
    margin-top: 20px;
}