@import '../index.css';

.sf-font-bold {
    font-weight: bold;
}

.sf-font-normal {
    font-weight: normal;
}

.sf-bottom-border {
    border-bottom: 1px solid var(--tertiary);
}

.sf-no-link-decor {
    text-decoration: none;
}

.sf-home-item-container {
    min-height: calc(1px + 7.5rem);
    border-bottom: 1px var(--tertiary) solid;
}

svg path,
svg rect{
  fill: var(--accent);
}