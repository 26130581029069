@import '../index.css';

.sf-searchbar {
    width: 75%;
    height: 2.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    outline: none;
    border: 1px solid var(--tertiary);
    border-radius: 5px;
    padding: 5px;
    background-color: var(--primary);
}

.sf-searchbar-text {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 1rem;
    background-color: var(--primary);
    color: var(--secondary);
}

.sf-searchbar:focus-within {
    border: 1px solid var(--accent);
    /* box-shadow: 0 0 0 2px var(--accent); */
}

.sf-searchbar-button {
    height: 100%;
    border: none;
    outline: none;
    background-color: var(--primary);
    color: var(--secondary);
    font-size: 1rem;
    cursor: pointer;
    margin-right: 10px;
}

.sf-searchbar-container {
    border-bottom: 1px var(--tertiary) solid;
    background-color: var(--primary);
    width: calc(100% + 24px);
    margin-left: -12px;
    padding: 1.5rem 0;
}

.sf-card {
    border: 1px solid transparent;
    border-radius: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sf-card::-webkit-scrollbar {
    display: none;
}

.sf-card-border {
    border: 1px solid var(--tertiary);
}

.sf-card-img {
    max-height: 7rem;
    max-width: 7rem;
    border-radius: 7px;
}

.sf-result {
    display: flex;
    align-items: center;
}

.sf-card-container {
    margin-top: -2rem;
    max-height: 80vh;
}

.sf-card-body {
    width: 100%;
}