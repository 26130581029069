.sf-text-bold {
    font-weight: bold;
    color: black;
}

.sf-no-text-decor {
    text-decoration: none;
}

.sf-searchbar-page-container {
    height: 7.5rem;
    width: calc(24px + 100%);
    margin-left: -12px;
    border-bottom: 1px var(--tertiary) solid;
}

.sf-results-button-container {
    height: 4rem;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sf-result-container {
    border: 1px solid var(--tertiary);
    border-radius: 10px;
    height: calc(7rem + 2px);
}

.sf-result-img {
    height: 7rem;
    width: 7rem;
    border-radius: 15px;
}

.sf-flex-col {
    display: flex;
    flex-direction: column;
}

.sf-result-hover:hover {
    background-color: rgba(var(--tertiary-rgb), 0.1);
    border-color: var(--accent);
}

.sf-song-title {
    font-size: 32px;
    font-weight: bold;
}

.sf-song-artist {
    font-size: 20px;
    font-weight: normal;
}

.sf-song-subtext {
    font-size: 16px;
    font-weight: normal;
}

.sf-song-cover {
    border-radius: 15px;
    height: 15rem;
    width: 15rem;
}

/* Dropdown Button */

/* The container <div> - needed to position the dropdown content */
.sf-dropdown {
    position: relative;
}

.sf-dropdown-button {
    height: 4rem;
    width: 5rem;
}

/* Dropdown Content (Hidden by Default) */
.sf-dropdown-content {
    position: absolute;
    background-color: var(--primary);
    width: 22.5vw;
    margin-top: 4.5rem;
    z-index: 100000;
    border: 1px solid var(--tertiary);
    border-radius: 15px;
}

/* Links inside the dropdown */
.sf-dropdown-content a {
    color: var(--secondary);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.sf-dropdown-content a:hover {
    background-color: var(--accent);
    border-radius: 15px;
}

/* Show the dropdown menu on hover */
.sf-dropdown:hover .sf-dropdown-content {
    display: block;
}

.star-rating {
    display: inline-flex;
    font-size: 1.5rem;
}

.star-rating input[type="radio"] {
    display: none;
}

.star-rating .fas.fa-star {
    color: var(--stars);
    /* Change the default color to --stars */
}

/* Remove this rule, as it's not needed */
/* .star-rating input[type="radio"]:checked ~ .fas.fa-star {
    color: var(--tertiary);
} */

/* Update this rule to set the color of the hovered star and all previous siblings to --stars */
.star-rating .fas.fa-star:hover,
.star-rating .fas.fa-star:hover~.fas.fa-star {
    color: var(--stars);
}

/* Add this rule to set the color of the stars following the hovered star to --tertiary */
.star-rating .fas.fa-star:hover~.fas.fa-star:not(:hover) {
    color: var(--tertiary);
}

/* Add this rule to set the color of the checked star and all previous siblings to --stars */
.star-rating input[type="radio"]:checked~.fas.fa-star {
    color: var(--stars);
}

/* Add this rule to set the color of the stars following the checked star to --tertiary */
.star-rating input[type="radio"]:checked~.fas.fa-star~.fas.fa-star {
    color: var(--tertiary);
}