:root {
    --primary: rgb(var(--primary-rgb));
    --secondary: rgb(var(--secondary-rgb));
    --tertiary: rgb(var(--tertiary-rgb));
    --tertiary-alt: rgb(var(--tertiary-alt-rgb));
    --primary-rgb: 255, 255, 255;
    --secondary-rgb: 0, 0, 0;
    --tertiary-rgb: 86, 86, 86;
    --tertiary-alt-rgb: 110, 110, 110;

    --primary-dark: rgb(var(--primary-dark-rgb));
    --secondary-dark: rgb(var(--secondary-dark-rgb));
    --tertiary-dark: rgb(var(--tertiary-dark-rgb));
    --tertiary-alt-dark: rgb(var(--tertiary-alt-dark-rgb));
    --primary-dark-rgb: 20, 24, 27;
    --secondary-dark-rgb: 233, 233, 233;
    --tertiary-dark-rgb: 114, 114, 114;
    --tertiary-alt-dark-rgb: 65, 65, 65;

    --primary-light: rgb(var(--primary-light-rgb));
    --secondary-light: rgb(var(--secondary-light-rgb));
    --tertiary-light: rgb(var(--tertiary-light-rgb));
    --tertiary-alt-light: rgb(var(--tertiary-alt-light-rgb));
    --primary-light-rgb: 255, 255, 255;
    --secondary-light-rgb: 0, 0, 0;
    --tertiary-light-rgb: 86, 86, 86;
    --tertiary-alt-light-rgb: 110, 110, 110;

    --accent: rgb(var(--accent-rgb));
    --accent-darker: rgb(var(--accent-darker-rgb));
    --accent-rgb: 0, 220, 37;
    --accent-darker-rgb: 0, 172, 29;
    --danger: rgb(var(--danger-rgb));
    --danger-rgb: 255, 66, 66;
    --stars: rgb(var(--stars-rgb));
    --stars-rgb: 255, 217, 64;
}

html,
body {
    max-width: 100%;
    background-color: var(--primary);
    /* overflow-x: hidden; */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

a,
a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
}

.sf-button-text-default {
    color: rgb(20, 24, 27);
}

.sf-danger {
    color: var(--danger) !important;
}

.sf-bg-danger {
    background-color: var(--danger) !important;
}

/* .sf-test {
    background-color: red;
    width: 100%;
    height: 1000vh;
} */

.sf-primary {
    color: var(--primary) !important;
}

.sf-bg-primary {
    background-color: var(--primary) !important;
}

.sf-secondary {
    color: var(--secondary) !important;
}

.sf-bg-secondary {
    background-color: var(--secondary) !important;
}

.sf-tertiary {
    color: var(--tertiary) !important;
}

.sf-bg-tertiary {
    background-color: var(--tertiary) !important;
}

.sf-tertiary-alt {
    color: var(--tertiary-alt) !important;
}

.sf-bg-tertiary-alt {
    background-color: var(--tertiary-alt) !important;
}

.sf-accent {
    color: var(--accent) !important;
}

.sf-bg-accent {
    background-color: var(--accent) !important;
}

.sf-accent-darker {
    color: var(--accent-darker) !important;
}

.sf-bg-accent-darker {
    background-color: var(--accent-darker) !important;
}

.sf-stars {
    color: var(--stars) !important;
}

.sf-bg-stars {
    background-color: var(--stars) !important;
}

.sf-danger {
    color: var(--danger) !important;
}

.sf-bg-danger {
    background-color: var(--danger) !important;
}

.sf-pfp {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
}

.sf-side {
    height: 100vh;
}

.sf-right-border {
    border-right: 1px solid var(--tertiary);
    top: 0;
}

.sf-left-border {
    border-left: 1px solid var(--tertiary);
    top: 0;
}

.sf-text-bold {
    font-weight: bold;
}

.sf-text-normal {
    font-weight: normal;
}

.sf-bg-blur {
    background: var(--primary) opacity(0.9) !important;
    backdrop-filter: blur(10px);
}

.sf-clickable {
    cursor: pointer;
}

.sf-underline-hover:hover {
    text-decoration: underline;
}

.sf-darken-hover {
    cursor: pointer;
}

.sf-darken-hover:hover {
    filter: brightness(0.95);
}

.sf-tertiary-alt-hover {
    color: var(--tertiary-alt);
}

.sf-tertiary-alt-hover:hover {
    color: var(--tertiary);
}

.sf-tertiary-hover {
    color: var(--tertiary);
}

.sf-tertiary-hover:hover {
    color: var(--tertiary-alt);
}

.sf-large-hover:hover {
    transform: scale(1.1);
}

.sf-small-hover:hover {
    transform: scale(0.9);
}

.sf-small-active:active {
    transform: scale(0.9);
}

.sf-no-highlight {
    -webkit-tap-highlight-color: transparent;
    outline-style: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sf-hw-100 {
    height: 100%;
    width: 100%;
}

.sf-w-100 {
    width: 100%;
}

.sf-h-100 {
    height: 100%;
}

.sf-z-1 {
    z-index: 1;
}

.sf-z-2 {
    z-index: 2;
}

.sf-z-3 {
    z-index: 3;
}

.sf-hover-circle {
    border: 0px;
    border-radius: 50%;
    background-color: transparent;
}

.sf-hover-circle:hover {
    background-color: rgba(var(--tertiary-rgb), 0.1);
}

.sf-anim-3 {
    transition: 0.3s;
}

.sf-bottom-post {
    height: 50vh;
}

.sf-vh-100 {
    height: 100vh;
}

.sf-form-control {
    border-radius: 5px;
    border: 1px solid var(--tertiary-alt);
    padding: 0 1rem;
    color: var(--secondary);
    background-color: var(--primary);
    resize: none;
}

.sf-form-control:focus {
    outline: none;
    border: 1px solid var(--accent);
}

.sf-navbar-item-hover {
    border: 1px solid transparent;
    /* border: 2px solid var(--accent); */
    transition: width ease .3s, border 0s;
    margin-top: .75rem;
    /* translate: -100% 0; */
    width: 0px;
}

.sf-navbar-item:hover .sf-navbar-item-hover {
    border: 1px solid var(--accent);
    /* transform: translate(100%, 0); */
    width: 70%;
}

.sf-active {
    border: 1px solid var(--accent);
    width: 70%;
}