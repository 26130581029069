@import '../index.css';

.sf-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sf-navbar {
    padding-top: 10px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.sf-navbar-item {
    /* padding: 2rem 0; */
    border: 1px solid transparent;
    transition: .3s;
}

/* .sf-navbar-item:hover {
    border: 1px solid var(--accent);
    transform: translate(100%, 0);
    width: 70%;
} */


.sf-navbar-profile {
    bottom: 2vh;
}

.sf-mobile-nav-pfp {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.sf-mobile-nav-pfp-container {
    padding: 5px;
    border-radius: 50%;
    transition: .3s;
}

.sf-mobile-nav-pfp-container:hover {
    background-color: var(--tertiary-alt);
}

.sf-mobile-navbar {
    border-top: var(--tertiary) .5px solid;
    padding: 10px;
    background-color: var(--primary);
}

.sf-nav-profile-full {
    text-decoration: none;
    border-top: 1px solid var(--tertiary);
    width: calc(100% + 16px);
    margin-left: -8px;
    transition: .3s;
}

.sf-nav-profile-full:hover {
    background-color: rgba(var(--tertiary-alt-rgb), 0.5);
}

.sf-nav-profile-item {
    padding: 15px;
}

.sf-nav-pfp {
    max-width: 3rem;
    max-height: 3rem;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
}