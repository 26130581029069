.sf-folio-item-img {
    /* width: 20vw;
    height: 20vw; */
    object-fit: cover;
    border-radius: 10px;
}

.sf-folio-text-box {
    width: 100%;
    height: 5rem;
}

.sf-folio-button {
    width: 15vw;
    min-width: 10rem;
    height: 5rem;
}