@import '../index.css';

.sf-padding-20 {
    padding: 20px;
}

.sf-text-area {
    resize: none;
    width: 100%;
    height: 100%;
    border: 1px solid var(--tertiary);
    border-radius: 5px;
    color: var(--secondary);
    background-color: transparent;
    background: opacity(0.5);
    outline: none;
    padding: 5px;
    font-size: 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* backdrop-filter: blur(6px); */
}

.sf-text-area::-webkit-scrollbar {
    display: none;
}

.sf-text-area:focus {
    border: 1px solid var(--accent);
}